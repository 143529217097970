import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2f92bd66"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "safe-takeoff-hover"
};
var _hoisted_2 = {
  "class": "safe-takeoff-notice"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_switch = _resolveComponent("el-switch");
  return _openBlock(), _createElementBlock(_Fragment, null, [$setup.editValue ? (_openBlock(), _createBlock($setup["HeaderBlock"], _mergeProps({
    key: 0,
    title: $setup.editValue.common.flyToWaylineMode === 'safely' ? '安全模式' : '省电模式'
  }, _ctx.$attrs), {
    wrapper: _withCtx(function () {
      return [_createVNode(_component_el_switch, {
        modelValue: $setup.editValue.common.flyToWaylineMode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.editValue.common.flyToWaylineMode = $event;
        }),
        "active-value": "pointToPoint",
        "inactive-value": "safely",
        "active-color": "#3C4DA5",
        "inactive-color": "#CBCBCB"
      }, null, 8 /* PROPS */, ["modelValue"])];
    }),
    middle: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepSvgIcon"], {
        name: "svg-notice",
        "class": "icon"
      }), _createElementVNode("div", _hoisted_2, _toDisplayString($setup.editValue.common.flyToWaylineMode === 'safely' ? '飞行器起飞，上升至首航点高度，再平飞至首航点。如果首航点低于起飞点，则起飞后平飞至首航点上方再下降。此模式起飞路径较长，但是可以规避起飞路径上的风险' : '飞行器起飞后，倾斜飞到首航点,此模式起飞路径最短，但是无法规避倾斜飞行路径上的风险'), 1 /* TEXT */)])];
    }),
    _: 1 /* STABLE */
  }, 16 /* FULL_PROPS */, ["title"])) : _createCommentVNode("v-if", true), $setup.editValue.common.flyToWaylineMode === 'pointToPoint' ? (_openBlock(), _createBlock($setup["TakeOffSecurityHeightParam"], {
    key: 1,
    modelValue: $setup.editValue.common.takeOffSecurityHeight,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
      return $setup.editValue.common.takeOffSecurityHeight = $event;
    })
  }, null, 8 /* PROPS */, ["modelValue"])) : _createCommentVNode("v-if", true), _createCommentVNode("  <HeaderBlock title=\"倾斜爬升到起始点\">"), _createCommentVNode("    <template #wrapper>"), _createCommentVNode("      <el-switch"), _createCommentVNode("        v-model=\"settings.flyToWaylineMode\""), _createCommentVNode("        active-value=\"pointToPoint\""), _createCommentVNode("        inactive-value=\"safely\""), _createCommentVNode("        active-color=\"#3C4DA5\""), _createCommentVNode("        inactive-color=\"#CBCBCB\">"), _createCommentVNode("      </el-switch>"), _createCommentVNode("    </template>"), _createCommentVNode("    <template #middle>"), _createCommentVNode("      <div class=\"safe-takeoff-hover\">"), _createCommentVNode("        <DeepSvgIcon name=\"svg-notice\" class=\"icon\"></DeepSvgIcon>"), _createCommentVNode("        <div class=\"safe-takeoff-notice\">"), _createCommentVNode("          <text>“倾斜爬升至起始点”开启，飞行器爬升到“安全起飞高度”后，再直线飞到航线起始点。</text>"), _createCommentVNode("          <DeepSvgIcon name=\"safe-mode\" style=\"width: 13rem;height: 8rem\"></DeepSvgIcon>"), _createCommentVNode("        </div>"), _createCommentVNode("      </div>"), _createCommentVNode("    </template>"), _createCommentVNode("  </HeaderBlock>")], 64 /* STABLE_FRAGMENT */);
}