import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1ce6f85a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "header-block"
};
var _hoisted_2 = {
  "class": "title-group"
};
var _hoisted_3 = {
  "class": "title"
};
var _hoisted_4 = {
  style: {
    "flex": "1"
  }
};
var _hoisted_5 = {
  key: 0,
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString($setup.props.title), 1 /* TEXT */), _createElementVNode("div", _hoisted_4, [_ctx.$slots.middle ? _renderSlot(_ctx.$slots, "middle", {
    key: 0
  }, undefined, true) : _createCommentVNode("v-if", true)]), _ctx.$slots.wrapper ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_renderSlot(_ctx.$slots, "wrapper", {}, undefined, true)])) : _createCommentVNode("v-if", true)]), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}