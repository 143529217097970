export enum ActionType {
  HOVER = 'hover',
  ROTATE_YAW = 'rotateYaw',
  GIMBAL_ROTATE = 'gimbalRotate',
  TAKE_PHOTO = 'takePhoto',
  START_RECORD = 'startRecord',
  STOP_RECORD = 'stopRecord',
  ZOOM = 'zoom',
  FOCUS = 'focus'
}
