import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-a8aba490"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  style: {
    "width": "100%",
    "padding-top": "16px",
    "display": "flex",
    "flex-direction": "row",
    "justify-content": "flex-end"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");
  var _component_el_form_item = _resolveComponent("el-form-item");
  var _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createBlock(_component_el_form, {
    ref: "ruleFormRef",
    rules: $setup.rules,
    "class": "drone-add",
    "label-position": "top",
    model: $setup.dataValue,
    "label-width": "120px",
    size: "default"
  }, {
    "default": _withCtx(function () {
      return [_createVNode(_component_el_form_item, {
        label: "航线名称",
        prop: "waylineTitle",
        style: {
          "margin-bottom": "1.5rem"
        }
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_el_input, {
            modelValue: $setup.dataValue.waylineTitle,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
              return $setup.dataValue.waylineTitle = $event;
            }),
            maxlength: "64",
            placeholder: "请输入(1-64)的航线名称",
            "show-word-limit": ""
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "选择飞行器",
        prop: "droneInfo",
        style: {
          "margin-bottom": "1.5rem"
        }
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["AircraftSelector2"], {
            modelValue: $setup.dataValue.droneInfo,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return $setup.dataValue.droneInfo = $event;
            }),
            onChange: $setup.handleAircraftChange
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "选择云台",
        prop: "payloadInfo",
        style: {
          "margin-bottom": "1.5rem"
        }
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["PayloadSelector2"], {
            modelValue: $setup.dataValue.payloadInfo,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return $setup.dataValue.payloadInfo = $event;
            }),
            onChange: $setup.handlePayloadChange,
            aircraft: $setup.dataValue.droneInfo
          }, null, 8 /* PROPS */, ["modelValue", "aircraft"])];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_form_item, {
        label: "航线类型",
        prop: "templateType",
        style: {
          "margin-bottom": "1.5rem"
        }
      }, {
        "default": _withCtx(function () {
          return [_createVNode($setup["TemplateTypeSelectorSingle"], {
            "text-type": "label",
            modelValue: $setup.dataValue.templateType,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = function ($event) {
              return $setup.dataValue.templateType = $event;
            })
          }, null, 8 /* PROPS */, ["modelValue"])];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("div", _hoisted_1, [_createVNode($setup["DeepButton"], {
        type: "primary",
        size: "small",
        onClick: _cache[4] || (_cache[4] = function ($event) {
          return $setup.submitForm($setup.ruleFormRef);
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("创建")];
        }),
        _: 1 /* STABLE */
      }), _createVNode($setup["DeepButton"], {
        size: "small",
        onClick: $setup.onClose
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode("取消")];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["rules", "model"]);
}