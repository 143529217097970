import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-23f8b4d8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.droneList, function (item) {
    return _openBlock(), _createElementBlock("div", {
      key: item.droneId,
      "class": "drone-item"
    }, [_createElementVNode("p", null, _toDisplayString(item.droneName), 1 /* TEXT */), _createElementVNode("span", {
      "class": _normalizeClass($setup.checked.includes(item.droneId) ? 'drone-check' : ''),
      onClick: function onClick($event) {
        return $setup.handleCheck(item.droneId);
      }
    }, null, 10 /* CLASS, PROPS */, _hoisted_1)]);
  }), 128 /* KEYED_FRAGMENT */);
}