import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3e3f8240"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "input-label"
};
var _hoisted_2 = ["value", "disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("input", {
    "class": "input",
    type: "number",
    value: $setup.dataValue,
    onFocus: $setup.handleFocus,
    onBlur: $setup.handleUpdateValue,
    onInput: $setup.limitNumberLength,
    disabled: $setup.props.disabled,
    style: _normalizeStyle({
      flex: $setup.props.flex && 1
    })
  }, null, 44 /* STYLE, PROPS, NEED_HYDRATION */, _hoisted_2), _createElementVNode("span", null, _toDisplayString($props.unit), 1 /* TEXT */)]);
}