import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-41e730aa"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "library"
};
var _hoisted_2 = {
  "class": "labels"
};
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  "class": "container"
};
var _hoisted_5 = {
  "class": "left"
};
var _hoisted_6 = {
  "class": "right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_DeepSvgIcon = _resolveComponent("DeepSvgIcon");
  var _component_el_tooltip = _resolveComponent("el-tooltip");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.actions, function (item) {
    return _createElementVNode("div", {
      "class": _normalizeClass(["action", $setup.currentIndex === item.key ? 'action-selected' : '']),
      key: item.key,
      onClick: function onClick($event) {
        return $setup.handleLabelChange(item.key);
      }
    }, [_createVNode(_component_el_tooltip, {
      placement: "right",
      content: item.name
    }, {
      "default": _withCtx(function () {
        return [_createVNode(_component_DeepSvgIcon, {
          name: item.icon,
          "class": "icon"
        }, null, 8 /* PROPS */, ["name"])];
      }),
      _: 2 /* DYNAMIC */
    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])], 10 /* CLASS, PROPS */, _hoisted_3);
  }), 64 /* STABLE_FRAGMENT */))]), _createElementVNode("div", _hoisted_4, [_createCommentVNode("      <transition name=\"slide-fade-transform\" mode=\"out-in\" >"), _createCommentVNode("        <component :is=\"rightComponent\" ></component>"), _createCommentVNode("      </transition>"), _createVNode($setup["PageHorizontalLayout"], null, {
    layout0: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_5, [_createVNode(_Transition, {
        name: "slide-fade-transform",
        mode: "out-in"
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.leftComponent)))];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    layout1: _withCtx(function () {
      return [_createElementVNode("div", _hoisted_6, [_createVNode(_Transition, {
        name: "slide-fade-transform",
        mode: "out-in"
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(), _createBlock(_resolveDynamicComponent($setup.rightComponent)))];
        }),
        _: 1 /* STABLE */
      })])];
    }),
    _: 1 /* STABLE */
  })])]);
}